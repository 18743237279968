import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = () => (
  <Layout>
    <Seo 
      title="About - Tideal"
      url="/about/"
    />
    <div id="aboutPage">
      <section>

        <div className="pageContent">

          <h3 className='pageHeader centered'>About</h3>

          <h1 className='pageTitle'>Hello There. We're Tideal.</h1>

          <h4 className='ingress'>Tideal is a community-powered and data-inspired climate action platform. Our vision is to see a growing mass of people live in a way that's for the long-term benefit of all life on this planet, including human life.</h4>

          <p>Climate change threatens much of what we hold dear on this planet. To have a fighting chance at slowing the heating down, let alone actually stopping it before we've gone too far, we need things to happen fast and in many fronts: we need international organizations and governments around the world to strive for ambitious climate policies; we need companies and research organizations to develop new low-emission technology, and we need them to cut back on the emissions that result from their own operations; and we need action and smart choices from individuals just like you.</p>

          <p className="lift">Individual action, taken by groups and communities of people, is what Tideal is all about.</p>

          <p>To realize our vision – to see a growing mass of people live in a way that's for the long-term benefit of all life on this planet – we are building a platform which offers lifestyle change challenges, helping you and the people around you turn the way you live into more climate-friendly.  We want the sustainable way of living to become the most desired way of living.</p>
          
          <p>Individual action, already in its own sake, can have a huge impact in our fight to stay cool. What's more, it can act as a catalyst for change in political decision making and as driver to change company policies. All those things tie together, and individual choice and action will always be the force that sparks change elsewhere, too.</p>

          <h3>The Company</h3>

          <p>Tideal was founded in 2018 and we like to think of it as a startup: we are working to solve huge problems where the solution is not obvious and success is not guaranteed. We've designed and developed our product and operating model to be scalable, providing high potential. This obviously comes with a high risk, which we are OK with: problems of this magnitude need solutions of equal magnitude.</p>

          <p>Formally, we are a Finnish private limited company and we are owned entirely by our founders and early team. We are based in Helsinki and Tampere, Finland.</p>

          <p>At the founding of our company, we wanted to put the purpose of our existence down on paper, to kind of act as our North Star in the highs and lows that will inevitably come to face an endeavour such as ours. In our Articles of Association we state the purpose of the company as "restraining globate climate warming and stopping it to a level that's beneficial to the fourishing of all life on the planet." This is the lens through which we view decisions on the future of both our product and our company.</p>

          <p className="lift">Purpose: restraining globate climate warming and stopping it to a level that's beneficial to the fourishing of all life on the planet.</p>

          <h3>Our guiding principles</h3>

          <p>Building the world's best community powered and data-inspired climate action platform is a venture full of product decisions - small and large - which we want to get right.</p>

          <p>To help us make these decisions, we've fleshed out some principles which keep pushing us to what is, to us, right.</p>

          
          <p><span className="principle">We are positive.</span><br /> We don't greenshame people. That doesn't work. Every change for the better is a good change.</p>

          
          <p><span className="principle">We show integrity.</span><br />We are open about the numbers behind our calculations, disclose our sources, and voice any assumptions we've made. We are positive, but also call it as it is: a small change is a small change, a big change is a big change.</p>

          
          <p><span className="principle">We are practical, not dogmatic.</span><br/>Our goal isn't material scarcity, our goal is to make this planet better off. At this point in time, achieving this goal means that we need to cut back on consumption.</p>

          
          <p><span className="principle">We are comprehensible.</span><br />We put numbers into perspective using understandable points of reference and use language that's easy to understand.</p>

          
          <p><span className="principle">We are action-oriented.</span><br />Though everyone can't do everything, everyone can do something. We offer actionable points of change for everyone.</p>

          <h3>The Team</h3>

          <p>We are a a small team of designers, technologists and everyday activists:</p>

          <ul>
            <li><span className="name">Jussi Ahola</span> <span className="title">Design, Co-founder, CEO</span></li>
            <li><span className="name">Jukka Hietamäki</span> <span className="title">Design, Co-founder</span></li>
            <li><span className="name">Marjaana Huitila</span> <span className="title">Design, Partner</span></li>
            <li><span className="name">Aino Kuokka</span> <span className="title">Development, Partner</span></li>
            <li><span className="name">Kalle Karikoski</span> <span className="title">Development, Partner</span></li>
            <li><span className="name">Teemu Uotila</span> <span className="title">Legal, Chairman of the Board</span></li>
          </ul>

        </div>

      </section>

    </div>

  </Layout>
)

export default AboutPage
